import * as urls from '@/config/net'
import api from '@/tools/api-tools'
import dayjs from 'dayjs'

class PaymentData {
  constructor (data) {
    this.paymentName = data.paymentName
    this.saleMoney = data.saleMoney
    this.returnMoney = data.returnMoney
    this.icon = data.icon
  }
}

class RechargeData {
  constructor (data) {
    this.paymentName = data.paymentName
    this.saleMoney = data.saleMoney
  }
}

class Statement {
  constructor () {
    this.imgs = {
      8: { img: require('@/assets/images/account-statement/wx-gather.png') },
      6: { img: require('@/assets/images/account-statement/alipay-gather.png') },
      3: { img: require('@/assets/images/account-statement/storecard.png') },
      5: { img: require('@/assets/images/account-statement/debt.png') },
      2: { img: require('@/assets/images/account-statement/Bank-card.png') },
      1: { img: require('@/assets/images/account-statement/cash.png') },
      101: { img: require('@/assets/images/account-statement/alipay.png') },
      102: { img: require('@/assets/images/account-statement/weixin.png') },
      103: { img: require('@/assets/images/account-statement/unionpay-acquire.png') }, // 银联收款
      204: { img: require('@/assets/images/account-statement/bestpay-gather.png') }, // 翼支付记账
      207: { img: require('@/assets/images/account-statement/yiqianbao-acquire.png') }, // 壹钱包收款
      1204: { img: require('@/assets/images/account-statement/bestpay.png') }, // 翼支付收款
      304: { img: require('@/assets/images/account-statement/cloudflashpay-acquire.png') }, // 云闪付记账
      1304: { img: require('@/assets/images/account-statement/cloudflashpay-acquire.png') }, // 云闪付收款
      '-2147483648': { img: require('@/assets/images/account-statement/more.png') }
    }
    this.params = {}
    const formatStr = 'YYYY/MM/DD'
    this.beginDate = dayjs().format(formatStr) // 开始日期
    this.endDate = dayjs().format(formatStr) // 截止时间
  }

  setParams ({ beginDate = this.beginDate, endDate = this.endDate, dateType = 'today' } = {}) {
    const params = {
      dateType: dateType,
      beginDate: beginDate,
      endDate: endDate
    }
    this.params = params
  }

  imgRender (payType) {
    return (this.imgs[payType] || {}).img || ''
  }

  _paymentDataSchema (data = []) {
    const list = []
    for (const item of data) {
      list.push(new PaymentData({
        paymentName: item.payTypeName,
        saleMoney: item.salesAmount,
        returnMoney: item.returnAmount,
        icon: this.imgRender(item.payType.toString())
      }))
    }
    return list
  }

  _rechargeDataSchema (data = []) {
    const list = []
    for (const item of data) {
      list.push(new RechargeData({
        paymentName: item.rechargeTypeName,
        saleMoney: item.rechargeAmount
      }))
    }
    return list
  }

  statementInitData (data) {
    return {
      name: data.shopName, // 店铺名称
      date: data.date, // 现金笔数
      repaymentMoney: data.extras.memberRepaymentAmount, // 会员还款金额
      expenditure: data.extras.shopExpenditure, // 门店支出金额
      totalName: data.pay.paySumModel.payTypeName, // 合计名称
      totalMoney: data.pay.paySumModel.salesAmount, // 合计销售金额
      totalRepaymentMoney: data.pay.paySumModel.returnAmount, // 合计退换货金额
      totalPaymentMoney: data.pay.paySumModel.totalAmount, // 合计金额
      totalPaymentCount: data.pay.paySumModel.tradeNumber, // 合计笔数
      totalRechargeCount: data.recharge.tradeNumber, // 充值计次笔数
      totalRechargeMoney: data.recharge.totalAmount, // 充值计次合计金额
      giveMoney: data.recharge.giftAmount, // 赠送金额
      printDate: new Date()
    }
  }

  paymentDataList () {
    return api.get(`${urls.apiUrl}/sales/account-statement`, {
      params: {
        ...this.params
      }
    }).then(res => {
      const data = res.data || {}
      const statementInitData = this.statementInitData(data)
      const paymentData = this._paymentDataSchema(data.pay.payModels)
      const rechargeData = this._rechargeDataSchema(data.recharge.rechargeModels)
      return { statementInitData, paymentData, rechargeData }
    })
  }
}

export default Statement
