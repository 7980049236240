import { remoteLogUrl } from '@/config/net'
import { getCookie } from '@/tools/cookie'
import device from '@/tools/device'
import axios from 'axios'

const token = getCookie('token') || getCookie('i200_token')
const project = 'MallVue'

const api = axios.create({
  timeout: 10000,
  baseURL: remoteLogUrl,
  headers: { 'X-Environment': process.env.NODE_ENV }
})

function _sendLog (url, data) {
  if (process.env.NODE_ENV !== 'production') return

  function asyncSend () {
    api.post(url, {
      project: project,
      platform: device.os,
      userToken: token,
      ...data
    })
  }

  setTimeout(asyncSend)
}

function _userAction ({ actionType = '', dataObj = {} }) {
  _sendLog('/user-action/record-action', { actionType, dataObj })
}

// 追踪创建订单
function createOrder (dataObj) {
  _userAction({ actionType: 'createOrder', dataObj })
}

// 追踪订单失败
function createOrderError (dataObj) {
  _userAction({ actionType: 'createOrderError', dataObj })
}

// 追踪PV
function userVisitPath (path) {
  _sendLog('/user-action/open-page', { path })
}

export default {
  createOrder,
  createOrderError,
  userVisitPath
}
