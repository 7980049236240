<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="每日对账单" v-if="isShowNavBar && appVersionInfo.isShowNavBar"></nav-bar>
    </template>
    <div class="statement-table">
      <table class="table-middle table-bordered table-condensed"
             cellspacing="0"
             cellpadding="0"
             border="0"
      >
        <tbody>
          <tr>
            <td colspan="10"
                class="text-center"
            >
              <p>{{ statementInitData.name }}</p>
              <p>收银对账单</p>
            </td>
          </tr>
          <tr>
            <td colspan="12"
                class="text-center"
            >{{ statementInitData.date }}</td>
          </tr>
          <tr>
            <td colspan="4"
                class="bold text-center"
            >支付方式</td>
            <td colspan="4"
                class="bold text-center"
            >销售</td>
            <td colspan="4"
                class="bold text-center"
            >退换货</td>
          </tr>
          <tr :key="item.paymentName + item.icon" v-for="item in paymentData">
            <td colspan="4"
                class="text-left pay-type-name"
            >
              <img :src="item.icon"
                   class="pay-icon"
              >{{ item.paymentName }}
            </td>
            <td colspan="4"
                class="text-right"
            >{{ item.saleMoney | currency }}</td>
            <td colspan="4"
                class="text-right"
            >{{ item.returnMoney | currency }}</td>
          </tr>
          <tr>
            <td colspan="4"
                class="text-center"
            >{{ statementInitData.totalName }}</td>
            <td colspan="4"
                class="text-right"
            >{{ statementInitData.totalMoney | currency }}</td>
            <td colspan="4"
                class="text-right"
            >{{ statementInitData.totalRepaymentMoney | currency }}</td>
          </tr>
          <tr>
            <td colspan="12"
                class="text-right"
            >
              合计：{{ statementInitData.totalPaymentCount }}笔，共 {{ statementInitData.totalPaymentMoney | currency }}
            </td>
          </tr>
          <tr :key="item.paymentName + item.rechargeType" v-for="(item, index) in rechargeData">
            <td class="text-center"
                colspan="4"
                :rowspan="rechargeData.length"
                v-show="index === 0"
            >
              <p class="bold">充值</p>
              <p>(储值)</p>
              <span>+</span>
              <p>(计次)</p>
            </td>
            <td colspan="4"
                class="text-center"
            >{{ item.paymentName }}</td>
            <td colspan="4"
                class="text-right"
            >{{ item.saleMoney | currency }}</td>
          </tr>
          <tr>
            <td colspan="12"
                class="text-right"
            >
              <p class="mb-5">
                合计：{{ statementInitData.totalRechargeCount }}笔 共{{ statementInitData.totalRechargeMoney | currency }}</p>
              <p>赠送：{{ statementInitData.giveMoney }}</p>
            </td>
          </tr>
          <tr>
            <td colspan="4"
                class="bold"
            >会员还款</td>
            <td colspan="8"
                class="text-right"
            >{{ statementInitData.repaymentMoney | currency }}</td>
          </tr>
          <tr>
            <td colspan="4"
                class="bold"
            >门店支出</td>
            <td colspan="8"
                class="text-right"
            >{{ statementInitData.expenditure | currency }}</td>
          </tr>
          <tr>
            <td colspan="4"
                class="bold"
            >负责人</td>
            <td colspan="8"
                class="text-right"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </page-view>
</template>
<style lang="scss" scoped>
@import './index.scss';
</style>
<script>
import appVersionMixins from '@/minxins/app-version-mixins'
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import device from '@/tools/device'
import Statement from '@/model/account-statement/index'
import RemoteLog from '@/tools/remote-log'

export default {
  mixins: [appVersionMixins],
  data () {
    const statement = new Statement()
    return {
      statement,
      paymentData: [], // 支付方式
      rechargeData: [], // 充值计次
      statementInitData: {}, // 账单基础数据
      params: {}
    }
  },
  components: { PageView, NavBar },
  created () {
  },
  mounted () {
    this.loadData()
    RemoteLog.userVisitPath('/account-statement')
  },
  beforeDestroy () {
  },
  methods: {
    loadData () {
      this.getSearchUrl()
      this.statement.setParams(this.params)
      this.statement.paymentDataList().then(res => {
        this.paymentData = res.paymentData
        this.rechargeData = res.rechargeData
        this.statementInitData = res.statementInitData
      })
    },
    getSearchUrl () {
      const queryObj = this.getQueryObj()
      this.params.beginDate = queryObj.beginDate
      this.params.endDate = queryObj.endDate
      this.params.dateType = queryObj.dateType
    },
    getQueryObj () {
      if (window.location.href.indexOf('?') > -1) return this.queryURL(decodeURIComponent(window.location.hash))
      return {}
    },
    // 标准化解析url参数列表
    queryURL (url) {
      const arr1 = url.split('?')
      const params = arr1[1].split('&')
      const obj = {}
      for (let i = 0; i < params.length; i++) {
        const param = params[i].split('=')
        obj[param[0]] = param[1]
      }
      return obj
    }
  },
  computed: {
    isShowNavBar () {
      if (device.androidPad || device.microMsg) {
        return false
      }
      return true
    }
  },
  watch: {}
}
</script>
